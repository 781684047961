<template>
  <Card class="mt-md-15" style="margin-bottom: 200px">
    <v-card-title>
      Administración
      <v-spacer></v-spacer>
      <v-btn @click="logout"> Salir </v-btn>
    </v-card-title>
    <router-view></router-view>
  </Card>
</template>

<script>
import Card from "@/components/card.vue";
export default {
  name: "Admin",
  components: {
    Card,
  },
  created() {
    document.title = "Indutec Bahía - Administración";
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>